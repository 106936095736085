import React, {useEffect, useState} from 'react';
import {COOKIE_NAME} from "../util/constant";
import internalService from "../api/internal-service";
import cookieHelper from "../util/cookie-helper";
import '../style/App.css';

const App = () => {
    const [redirecting, setRedirecting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        // Attempt to fetch the internal service to set the cookie
        internalService.ping()
            .then(response => response.text())
            .then(responseBody => cookieHelper.set(COOKIE_NAME, decodeURIComponent(responseBody), 365))
            .then(() => setRedirecting(true))
            .then(() => window.location.href = document.referrer === '' || document.referrer === window.location.href ? '/' : document.referrer)
            .catch(({message}) => cookieHelper.set(COOKIE_NAME, message, 1 / 24))
            .finally(() => setLoading(false));
    }, []);

    // Display a spinner while the ping request is being made
    if (loading) return <div className="spinner"></div>;

    // Avoid rendering the component if the user is being redirected to the targetHref
    if (redirecting) return <></>;

    return (
        <>
            <main>
                <h1>Unauthorized</h1>
                <p>If you feel that you have reached this page in error, please contact the system administrator.</p>
            </main>
            <footer className="app">
                Copyright &copy; {new Date().getFullYear()} D2G Group LLC All Rights Reserved
            </footer>
        </>
    );
};

export default App;