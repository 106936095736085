const date = new Date();

const cookieHelper = {
    /**
     * Set a cookie with the given name, value, and expiration date.
     * If the cookie already exists, it will be overwritten.
     * @param name - The name of the cookie
     * @param value - The value of the cookie
     * @param expires - The number of days until the cookie expires
     */
    set: (name: string, value: string, expires: number) => {
        date.setTime(date.getTime() + (expires * 24 * 60 * 60 * 1000));
        const expiresIn = "; expires=" + date.toUTCString();
        document.cookie = `${name}=${value}${expiresIn}}; path=/; SameSite=Lax`;
    },
    /**
     * Get the value of the cookie with the given name.
     * @param name - The name of the cookie
     * @returns The value of the cookie, or an empty string if the cookie does not exist
     */
    exists: (name: string): boolean => document.cookie.split(';').some((cookie) => cookie.trim().startsWith(name + '='))
};

export default cookieHelper;