import {ENDPOINT} from "../util/constant";

const TIMEOUT = 'timeoutPromise';
const internalService = {
    ping: async () => {
        const pingPromise = fetch(ENDPOINT);
        const timeoutPromise = new Promise((resolve) => setTimeout(resolve, 2500, TIMEOUT));
        const response = await Promise.race([pingPromise, timeoutPromise]);
        if (response === TIMEOUT) throw new Error('Request timed out after 2500ms');
        return response as Response;
    }
}

export default internalService;